/** @jsx jsx */
import { jsx } from "theme-ui";

import { useEffect } from "react";
import { navigate } from "gatsby";

const Index = (/* { data } */) => {
  useEffect(() => navigate("/"));
  return <div />;
};

export default Index;
